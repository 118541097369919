<template>
  <div id="pic-wrapper" ref="wrapper">
    <div :style="{background:'url('+bkImage+')'}" class="content" id="my-pic">
      <!--雨量数据-->
      <div class="rain">
        <RainGroup :liveData="siteItem"></RainGroup>
      </div>
      <!--赣江水位-->
      <div v-if="zjWaterX" :style="{left:zjWaterX+'px',top:zjWaterY+'px'}" class="zj-water">
        <LiveData :liveData="zjWater"></LiveData>
      </div>
      <!--赣江水位-->
      <div v-if="subX" :style="{left:subX+'px',top:subY+'px'}" class="subWater">
        <LiveData :liveData="subWater"></LiveData>
      </div>
    </div>
  </div>
</template>

<script>
import basepage from './common/basepage.js';
import LiveData from './components/liveItem.vue'
import RainGroup from './components/rainGroup'
export default {
  mixins: [basepage],
  components: {
    LiveData,
    RainGroup,
  },
  data() {
    return {
      bkImage: '',//背景图
      uid: 'SYS00182GEA',
      siteIndex: null,
      siteItem: {},
      option: {
        targetX: 1920,
        targetY: 1080,
        targetRatio: 16 / 9
      },
      zjWaterX: null,
      zjWaterY: null,
      subX: null,
      subY: null,
    }
  },
  computed: {
    zjWater() {
      let data = {
        title: this.siteItem.mainTitle,
        num: this.siteItem.mainWater,
        unit: 'm'
      }
      return data
    },
    subWater() {
      let data = {
        title: this.siteItem.subTitle,
        num: this.siteItem.subWater,
        unit: 'm'
      }
      return data
    }
  },

  methods: {
    on_siteDataReady(res) {
      if (res && res.sites.length > 0) {
        let siteArr = res.sites
        //未找到数据标识
        if (!this.siteIndex) {
          this.siteIndex = siteArr.findIndex(item => item.mainUid == this.uid)
        }
        //取出对应数据
        let siteData = siteArr[this.siteIndex]
        //第一次需要设置
        if (this.bkImage == '') {
          this.bkImage = 'https://iot.jjyip.com' + siteData.bkImage
          this.zjWaterX = siteData.mainX || 390
          this.zjWaterY = siteData.mainY || 420
          this.subX = siteData.subX || 871
          this.subY = siteData.subY || 779
        }

        this.siteItem = siteData
      }
    },

    //获取数据
    getSiteData() {
      let json = {
        "cmd": "siteData",
      }
      this.send(json)
    }
  },
  created() {
    // this.setScreen()
  },
  mounted() {
    this.on('siteDataReady', (res) => {
      this.on_siteDataReady(res);
    })
    this.on('connected', (res) => {
      this.getSiteData(); //连接成功再发送cmd
    })

  },
  destroyed() {
  },
}
</script>

<style lang="scss">
$h: 1080px;
$w: 1920px;
#pic-wrapper {
  position: relative;
  /* 设计稿 */
  width: 1920px;
  height: 1080px;
  // background: red;

  box-sizing: border-box;
  transform-origin: left top;
}
.content {
  // background: url("../../assets/pic_a.png");
  height: $h !important;
  width: $w !important;
  background-size: $w $h !important;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  position: absolute;

  .rain {
    position: absolute;
    // height: 50px;
    width: 200px;
    top: 43px;
    left: 321px;
  }

  .zj-water {
    position: absolute;
    // height: 50px;
    width: 200px;
    // top: 402px;
    // left: 390px;
  }
  .subWater {
    position: absolute;
    // height: 50px;
    width: 200px;
    top: 779px;
    left: 871px;
  }
}
</style>